import React from "react";
import { differenceInDays, format, parse, parseISO, startOfMonth, subMonths } from "date-fns";
import { useRouter } from "next/router";
import { DATE_OPTIONS, DateOptions } from "../../src/utils/constants";
import { groupByOptions, UseGroupBy, useGroupBy } from "./groupBy/useGroupBy";
import {
  TShowGroupByDropdown,
  useShowGroupByDropdown,
} from "./showGroupByDropdown/showGroupByDropdown";
import { getPreviousYearDates } from "./utils/getPreviousYearDates";
import { getPreviousPeriodDates } from "./utils/getPreviousPeriodDates";
import { getDefaultDateDropdownSettings } from "./utils/getDefaultDateDropdownSettings";
import { useStoresContext } from "../StoresContext";
import { FirstWeekDay } from "../../types";

export type RangeType = { from: string; to: string };
export type CompareRangeType = { prevStartDate: string; prevEndDate: string };

type DateDropdownContextProps = {
  dateRange: RangeType;
  setSelectedDateIndex: React.Dispatch<React.SetStateAction<DateOptions>>;
  setDateRange: React.Dispatch<React.SetStateAction<any>>;
  selectedDateIndex: number;
  selectedDate: (typeof DATE_OPTIONS)[number];
  beforeNow: number;
  compareRange: CompareRangeType;
  compareSelect: CompareDropdownType;
  changeCompareSelect: React.Dispatch<React.SetStateAction<CompareDropdownType>>;
} & UseGroupBy &
  TShowGroupByDropdown;

export type CompareDropdownType = "previousYear" | "previousPeriod";

const DateDropdownContext = React.createContext<DateDropdownContextProps>({
  dateRange: {
    from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    to: format(new Date(), "yyyy-MM-dd"),
  },
  setSelectedDateIndex: () => {},
  setDateRange: () => {},
  selectedDateIndex: DateOptions.MTD,
  selectedDate: DATE_OPTIONS[DateOptions.MTD],
  beforeNow: differenceInDays(new Date(), parseISO(format(startOfMonth(new Date()), "yyyy-MM-dd"))),
  compareRange: {
    prevStartDate: format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
    prevEndDate: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
  },
  compareSelect: "previousPeriod",
  changeCompareSelect: () => {},
  groupByValue: groupByOptions[0].id,
  onGroupByChange: (e: any) => {},
  setGroupByValue: () => {},
  showGroupByDropdown: false,
  setShowGroupByDropdown: () => {},
});

function DateDropdownProvider(props: any) {
  const { selectedStore } = useStoresContext();
  const firstWeekDay = selectedStore?.firstWeekDay || FirstWeekDay.MONDAY;

  const router = useRouter();
  const localStorageKey = "dateRange";

  const getInitialDateRange = () => {
    if (typeof window === "undefined")
      return {
        from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
        to: format(new Date(), "yyyy-MM-dd"),
      };
    const savedDateRange = localStorage.getItem(localStorageKey);

    if (savedDateRange) {
      return JSON.parse(savedDateRange);
    }

    return {
      from: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      to: format(new Date(), "yyyy-MM-dd"),
    };
  };

  const [dateRange, setDateRange] = React.useState<RangeType>(getInitialDateRange);

  React.useEffect(() => {
    setDateRange(getInitialDateRange());
  }, [firstWeekDay]);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem(localStorageKey, JSON.stringify(dateRange));
    }
  }, [dateRange, firstWeekDay]);

  const [compareRange, setCompareRange] = React.useState<CompareRangeType>({
    prevStartDate: format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
    prevEndDate: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
  });

  const [selectedDateIndex, setSelectedDateIndex] = React.useState(DateOptions.MTD);
  const [selectedDate, setSelectedDate] = React.useState<(typeof DATE_OPTIONS)[number]>(
    DATE_OPTIONS[DateOptions.MTD],
  );
  const [beforeNow, setBeforeNow] = React.useState(
    differenceInDays(new Date(), parseISO(dateRange.from)),
  );
  const { groupByValue, onGroupByChange, setGroupByValue } = useGroupBy();
  const { setShowGroupByDropdown, showGroupByDropdown } = useShowGroupByDropdown();
  const [compareSelect, changeCompareSelect] =
    React.useState<CompareDropdownType>("previousPeriod");

  React.useEffect(
    () => setBeforeNow(differenceInDays(new Date(), parseISO(dateRange.from))),
    [dateRange.from, firstWeekDay],
  );

  React.useEffect(() => {
    if (router.pathname === "/cohorts") {
      setSelectedDateIndex(DateOptions.LastYear);
      setSelectedDate(DATE_OPTIONS[DateOptions.LastYear]);
    }
  }, []);

  React.useEffect(() => {
    if (selectedDateIndex === DateOptions.Custom) {
      setSelectedDate(DATE_OPTIONS[selectedDateIndex]);
      return;
    }
    const range = getDefaultDateDropdownSettings(selectedDateIndex, firstWeekDay);
    setDateRange(range);
    setSelectedDate(DATE_OPTIONS[selectedDateIndex]);
  }, [selectedDateIndex, firstWeekDay]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeCompareRange = React.useCallback(
    (newSelectValue: CompareDropdownType) => {
      const parsedDateFrom = parse(dateRange.from, "yyyy-MM-dd", new Date());
      const parsedDateTo = parse(dateRange.to, "yyyy-MM-dd", new Date());
      switch (newSelectValue) {
        case "previousYear": {
          const { prevFrom, prevTo } = getPreviousYearDates(
            parsedDateFrom,
            parsedDateTo,
            selectedDateIndex,
          );
          setCompareRange({
            prevStartDate: format(prevFrom, "yyyy-MM-dd"),
            prevEndDate: format(prevTo, "yyyy-MM-dd"),
          });
          break;
        }
        case "previousPeriod": {
          const { prevFrom, prevTo } = getPreviousPeriodDates(
            parsedDateFrom,
            parsedDateTo,
            selectedDateIndex,
            firstWeekDay,
          );

          setCompareRange({
            prevStartDate: format(prevFrom, "yyyy-MM-dd"),
            prevEndDate: format(prevTo, "yyyy-MM-dd"),
          });
          break;
        }
        default:
          break;
      }
    },
    [dateRange.from, dateRange.to, selectedDateIndex, firstWeekDay],
  );

  React.useEffect(() => {
    changeCompareRange(compareSelect);
  }, [changeCompareRange, compareSelect, dateRange, selectedDateIndex, firstWeekDay]);

  const value = React.useMemo(
    () => ({
      dateRange,
      setSelectedDateIndex,
      setDateRange,
      selectedDateIndex,
      selectedDate,
      beforeNow,
      compareRange,
      compareSelect,
      changeCompareSelect,
      groupByValue,
      onGroupByChange,
      setGroupByValue,
      showGroupByDropdown,
      setShowGroupByDropdown,
    }),
    [
      dateRange,
      selectedDateIndex,
      selectedDate,
      beforeNow,
      compareRange,
      compareSelect,
      groupByValue,
      onGroupByChange,
      setGroupByValue,
      showGroupByDropdown,
      setShowGroupByDropdown,
    ],
  );

  return <DateDropdownContext.Provider value={value} {...props} />;
}

function useDateDropdown() {
  const context = React.useContext(DateDropdownContext);

  if (context === undefined) {
    throw new Error("useDateDropdown must be used within an DateDropdownProvider");
  }
  return context;
}

export { DateDropdownProvider, useDateDropdown, DateDropdownContext };
