import React, { createContext, useContext, useMemo } from "react";
import { useRouter } from "next/router";
import { useStoresContext } from "../StoresContext";

interface ResponseViewContextType {
  isAdditionalResponseSettings: boolean;
}

const ResponseViewContext = createContext<ResponseViewContextType | undefined>(undefined);

function ResponseViewProvider(props: any) {
  const { selectedStore } = useStoresContext();
  const isNewDashboard = selectedStore?.featureFlags?.newDashboard ?? false;
  const { pathname } = useRouter();

  const isAdditionalResponseSettings = isNewDashboard && pathname === "/settings/edit-goals";

  const value = useMemo(() => ({ isAdditionalResponseSettings }), [isAdditionalResponseSettings]);
  return <ResponseViewContext.Provider value={value} {...props} />;
}

function useResponseView() {
  const context = useContext(ResponseViewContext);
  if (context === undefined) {
    throw new Error("error response view");
  }
  return context;
}

export { ResponseViewContext, ResponseViewProvider, useResponseView };
