import { MutateOptions, useMutation, useQueryClient } from "react-query";
import { PinnedCardOrderItem, PinnedService } from "../../lib/pinned/types";
import api, { queryFetcher } from "../../src/APIResolvers";
import { useStoresContext } from "../StoresContext";

type UpdatePinnedCardProps = {
  storeId: string;
  service: PinnedService;
  metricId: number;
  isNewDashboard: boolean;
};

type UpdatePinnedCardDisplayOrderProps = {
  storeId: string;
  pinnedCardsDisplayOrder: PinnedCardOrderItem[];
};

export function useUpdatePinnedCard(): {
  onAddPinnedCard: (
    variables: UpdatePinnedCardProps,
    options?: MutateOptions<any, unknown, UpdatePinnedCardProps, unknown>,
  ) => Promise<any>;
  onDeletePinnedCard: (
    variables: UpdatePinnedCardProps,
    options?: MutateOptions<any, unknown, UpdatePinnedCardProps, unknown>,
  ) => Promise<any>;
  onUpdatePinnedCardsDisplayOrder: (
    variables: UpdatePinnedCardDisplayOrderProps,
    options?: MutateOptions<any, unknown, UpdatePinnedCardDisplayOrderProps, unknown>,
  ) => Promise<any>;
  isDeletePinnedCardLoading: boolean;
} {
  const client = useQueryClient();
  const { selectedStore } = useStoresContext();
  const storeId = selectedStore?.id || "";

  const { mutateAsync: onAddPinnedCard } = useMutation(
    (values: UpdatePinnedCardProps) =>
      queryFetcher(api.updatePinnedCard.ENDPOINTS.updatePinnedCard(), {
        method: "POST",
        body: JSON.stringify(values),
      }),
    {
      onSuccess: () => {
        // update allMetrics
        client.invalidateQueries(["KPIMetrics", storeId]);
      },
    },
  );

  const { mutateAsync: onDeletePinnedCard, isLoading: isDeletePinnedCardLoading } = useMutation(
    (values: UpdatePinnedCardProps) =>
      queryFetcher(api.updatePinnedCard.ENDPOINTS.updatePinnedCard(), {
        method: "DELETE",
        body: JSON.stringify(values),
      }),
    {
      onSuccess: () => {
        // update allMetrics
        client.invalidateQueries(["KPIMetrics", storeId]);
      },
    },
  );

  const { mutateAsync: onUpdatePinnedCardsDisplayOrder } = useMutation(
    (values: UpdatePinnedCardDisplayOrderProps) =>
      queryFetcher(api.updatePinnedCard.ENDPOINTS.updatePinnedCardsDisplayOrder(), {
        method: "POST",
        body: JSON.stringify(values),
      }),
    {
      onSuccess: () => {
        // update allMetrics
        client.invalidateQueries(["KPIMetrics", storeId]);
      },
    },
  );

  return {
    onAddPinnedCard,
    onDeletePinnedCard,
    onUpdatePinnedCardsDisplayOrder,
    isDeletePinnedCardLoading,
  };
}
