import {
  differenceInDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isSameMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import { DateOptions } from "../../../src/utils/constants";
import { getWeekStartDay } from "../../../src/utils/getWeekStartDay";
import { FirstWeekDay } from "../../../types";

export const getPreviousPeriodDates = (
  parsedDateFrom: Date,
  parsedDateTo: Date,
  selectedDateIndex: DateOptions,
  firstWeekDay: FirstWeekDay = FirstWeekDay.MONDAY,
) => {
  let prevFrom = new Date();
  let prevTo = new Date();

  const weekStartDay = getWeekStartDay(firstWeekDay);

  switch (selectedDateIndex) {
    case DateOptions.Today:
      prevFrom = startOfDay(subDays(new Date(), 1));
      prevTo = startOfDay(subDays(new Date(), 1));

      break;
    case DateOptions.Yesterday:
      prevFrom = startOfDay(subDays(new Date(), 2));
      prevTo = startOfDay(subDays(new Date(), 2));

      break;
    case DateOptions.LastSevenDays:
      prevFrom = startOfDay(subDays(new Date(), 14));
      prevTo = endOfDay(subDays(new Date(), 8));
      break;

    case DateOptions.LastWeek:
      prevFrom = startOfWeek(subWeeks(new Date(), 2), { weekStartsOn: weekStartDay });
      prevTo = endOfWeek(subWeeks(new Date(), 2), { weekStartsOn: weekStartDay });
      break;
    case DateOptions.LastMonth:
      prevFrom = startOfMonth(subMonths(new Date(), 2));
      prevTo = endOfMonth(subMonths(new Date(), 2));
      break;
    case DateOptions.LastThirtyDays:
      prevFrom = sub(new Date(), { days: 60 });
      prevTo = sub(new Date(), { days: 31 });
      break;
    case DateOptions.LastSixtyDays:
      prevFrom = sub(new Date(), { days: 120 });
      prevTo = sub(new Date(), { days: 61 });
      break;
    case DateOptions.LastNinetyDays:
      prevFrom = sub(new Date(), { days: 180 });
      prevTo = sub(new Date(), { days: 91 });
      break;
    case DateOptions.LastOneTwentyDays:
      prevFrom = sub(new Date(), { days: 240 });
      prevTo = sub(new Date(), { days: 121 });
      break;
    case DateOptions.LastThreeSixtyFiveDays:
      prevFrom = sub(new Date(), { days: 730 });
      prevTo = sub(new Date(), { days: 366 });
      break;
    case DateOptions.LastThreeMonths:
      prevFrom = sub(new Date(), { days: 6 });
      prevTo = sub(new Date(), { days: 4 });
      break;
    case DateOptions.LastSixMonths:
      prevFrom = sub(new Date(), { days: 12 });
      prevTo = sub(new Date(), { days: 7 });
      break;
    case DateOptions.LastNineMonths:
      prevFrom = sub(new Date(), { days: 18 });
      prevTo = sub(new Date(), { days: 10 });
      break;
    case DateOptions.LastTwelveMonths:
      prevFrom = sub(new Date(), { days: 24 });
      prevTo = sub(new Date(), { days: 13 });
      break;
    case DateOptions.LastTwentyFourMonths:
      prevFrom = sub(new Date(), { days: 48 });
      prevTo = sub(new Date(), { days: 25 });
      break;
    case DateOptions.LastThirtySixMonths:
      prevFrom = sub(new Date(), { days: 72 });
      prevTo = sub(new Date(), { days: 37 });
      break;
    case DateOptions.MTD:
      prevFrom = startOfMonth(subMonths(new Date(), 1));
      if (prevTo.getDate() === 1) {
        prevTo = subMonths(new Date(), 1);
      } else {
        prevTo = subMonths(subDays(new Date(), 1), 1);
      }
      break;
    case DateOptions.LastYear:
      prevFrom = startOfYear(subYears(new Date(), 2));
      prevTo = endOfYear(subYears(new Date(), 2));

      break;
    case DateOptions.YTD:
      const from = startOfYear(new Date());
      const to = new Date();
      prevFrom = startOfYear(subYears(from, 1));
      prevTo = subYears(to, 1);

      break;
    case DateOptions.Custom:
      const isFullMonthSelected = isSameMonth(parsedDateFrom, parsedDateTo);

      // const isFullYearSelected = isFullYear(parsedDateFrom, parsedDateTo);
      // const isMTDSelected = isMonthToDate(parsedDateFrom, parsedDateTo);
      // const isYTDSelected = isYearToDate(parsedDateFrom, parsedDateTo);

      // if (isFullMonthSelected) {
      //   prevFrom = startOfMonth(subMonths(parsedDateFrom, 1));
      //   prevTo = endOfMonth(subMonths(parsedDateTo, 1));
      // } else if (isFullYearSelected) {
      //   prevFrom = startOfYear(subYears(parsedDateFrom, 1));
      //   prevTo = endOfYear(subYears(parsedDateTo, 1));
      // } else if (isMTDSelected) {
      //   prevFrom = startOfMonth(subMonths(parsedDateFrom, 1));
      //   prevTo = subMonths(parsedDateTo, 1);
      // } else if (isYTDSelected) {
      //   prevFrom = startOfYear(subYears(parsedDateFrom, 1));
      //   prevTo = subYears(parsedDateTo, 1);
      // } else {
      //   const daysDifference = differenceInDays(parsedDateTo, parsedDateFrom);
      //   prevTo = subDays(parsedDateFrom, 1);
      //   prevFrom = subDays(prevTo, daysDifference);
      // }

      const daysDifference = differenceInDays(parsedDateTo, parsedDateFrom);
      if (isFullMonthSelected) {
        prevTo = subMonths(parsedDateTo, 1);
        prevFrom = subMonths(parsedDateFrom, 1);
      } else {
        prevTo = subDays(parsedDateFrom, 1);
        prevFrom = subDays(prevTo, daysDifference);
      }
      break;
    default:
      break;
  }
  return {
    prevFrom,
    prevTo,
  };
};
