import { TargetsLastYearReqBody } from "../../pages/api/targets/last-year";
import { createFilteredUrlParams } from "../utils/createFilteredUrlParams";

export interface IGetAllStoreTargets {
  storeId: string;
}

const baseUrl = "/api/targets";
const ENDPOINTS = {
  allStoreTargets: (params: IGetAllStoreTargets) =>
    `${baseUrl}?${createFilteredUrlParams<IGetAllStoreTargets>(params)}`,
  targetsByPeriod: (params: TargetsLastYearReqBody) =>
    `${baseUrl}/last-year?${createFilteredUrlParams<TargetsLastYearReqBody>(params)}`,
  updateTargetValue: () => baseUrl,
  addNewTargets: () => baseUrl,
  deleteTargets: () => baseUrl,
};

export { ENDPOINTS };
