import { TogglePinnedStatusParams } from "../types";
import { PinnedCards } from "../../../lib/pinned/types";
import { getObjectKeyBySource, transformObjectKeys } from "./helpers";

export const addCardToState = (
  prevState: PinnedCards,
  { source: oldSource, cardKey, cardData }: TogglePinnedStatusParams,
) => {
  const source = getObjectKeyBySource(oldSource);
  const sourceToChange = prevState?.[source] || {};
  const newSource = {
    ...sourceToChange,
    [cardKey]: cardData,
  };
  const result = {
    ...prevState,
    [source]: newSource,
  };

  return transformObjectKeys(result) as PinnedCards;
};
