import { TransformCardsReturnType } from "../PinnedCards/helpers/helpers";

export const reorderMetrics = (dropItems: any, oldIndex: number, newIndex: number) => {
  const removed = dropItems[oldIndex];
  const updatedItems = [...dropItems.slice(0, oldIndex), ...dropItems.slice(oldIndex + 1)];
  const newItems = [...updatedItems.slice(0, newIndex), removed, ...updatedItems.slice(newIndex)];
  return newItems;
};

export const getStorageKey = (groupId: string) => `tempCardsOrder_${groupId}`;

export const clearGroupOrderFromStorage = (groupId: string) => {
  localStorage.removeItem(getStorageKey(groupId));
};

export const isEqualOrder = (
  newCards: TransformCardsReturnType[],
  oldCards: TransformCardsReturnType[],
) => JSON.stringify(newCards) === JSON.stringify(oldCards);

export const saveGroupOrderToStorage = (
  groupId: string,
  newDropItems: TransformCardsReturnType[],
) => {
  localStorage.setItem(getStorageKey(groupId), JSON.stringify(newDropItems));
};

export const loadGroupOrderFromStorage = (groupId: string): TransformCardsReturnType[] | null => {
  const savedOrder = localStorage.getItem(getStorageKey(groupId));
  return savedOrder ? JSON.parse(savedOrder) : null;
};
