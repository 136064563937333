import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import {
  IKPIMetricsDetails,
  IMetricsMutationResponse,
  useOperateKPIMetrics,
} from "../../src/hooks/useOperateKPIMetrics";
import { useStoresContext } from "../StoresContext";
import { TGroupKeys } from "../../src/pages/Kpi/components/DialogMetrics/dialogsSettings";
import { useUpdatePinnedCard } from "../PinMetricsContext/useUpdatePinnedCard";
import { getEnumValueByTitle } from "../PinMetricsContext/helpers/helpers";
import { PinnedService } from "../../lib/pinned/types";
import { changeMetricsAbbreviation } from "../../src/pages/Kpi/utils/modifyGroupName";
import { clearGroupOrderFromStorage } from "../../src/pages/Dashboard/models/PinnedCardsDND/utils";

interface PinKPIMetricsContextType {
  isKPIMetricCardPinned: (details: IKPIMetricsDetails) => boolean;
  togglePinnedStatusKPIMetrics: (details: IKPIMetricsDetails) => void;
  isLoading: boolean;
}

interface IPinnedCardsData extends IMetricsMutationResponse {}
export const currentChannel = (source: string, reverse = false) =>
  reverse
    ? ((source === "metaAds" ? "fbAds" : source) as PinnedService)
    : ((source === "fbAds" ? "metaAds" : source) as TGroupKeys);

const PinKPIMetricsContext = createContext<PinKPIMetricsContextType | undefined>(undefined);

function PinKPIMetricProvider(props: any) {
  const [pinnedCardsData, setPinnedCardsData] = useState<IPinnedCardsData[] | null>(null);
  const { selectedStore } = useStoresContext();
  const { allMetrics, isLoading } = useOperateKPIMetrics();
  const isNewDashboard = selectedStore?.featureFlags?.newDashboard ?? false;
  const { onDeletePinnedCard } = useUpdatePinnedCard();

  useEffect(() => {
    if (!allMetrics) return;
    setPinnedCardsData(allMetrics);
  }, [allMetrics]);

  const isKPIMetricCardPinned = ({ channel, metricName, groupId }: IKPIMetricsDetails): boolean =>
    pinnedCardsData?.some(
      (metric) =>
        metric.group === "starred" &&
        metric.channel === currentChannel(channel) &&
        metric.metricName === metricName,
    ) ?? false;

  const togglePinnedStatusKPIMetrics = ({ channel, metricName, groupId }: IKPIMetricsDetails) => {
    if (selectedStore?.id) {
      const service = currentChannel(channel, true);
      console.log("togglePinnedStatusKPIMetrics", { metricName, service });
      // TODO: FIX TYPES
      // @ts-ignore
      const source = (service: any) => changeMetricsAbbreviation[service] ?? service;
      const metricId = getEnumValueByTitle(
        metricName as string,
        source(service) as PinnedService,
      ) as unknown as number;

      try {
        if (
          isKPIMetricCardPinned({
            channel: currentChannel(service, true) as TGroupKeys,
            metricName,
            groupId,
          })
        ) {
          onDeletePinnedCard({
            storeId: selectedStore.id,
            service: source(service) as PinnedService,
            metricId,
            isNewDashboard,
          }).then(() => groupId && clearGroupOrderFromStorage(groupId));
        }
      } catch {
        toast.error("Something went wrong, try again later");
      }
    }
  };
  const value = useMemo(
    () => ({ isKPIMetricCardPinned, togglePinnedStatusKPIMetrics, isLoading }),
    [isKPIMetricCardPinned, togglePinnedStatusKPIMetrics, isLoading],
  );
  return <PinKPIMetricsContext.Provider value={value} {...props} />;
}

function usePinKPIMetrics() {
  const context = useContext(PinKPIMetricsContext);
  if (context === undefined) {
    throw new Error("usePinKPIMetrics must be used within an PinKPIMetricProvider");
  }
  return context;
}

export { PinKPIMetricsContext, PinKPIMetricProvider, usePinKPIMetrics };
