import { IKpiGroups } from "../components/DialogMetrics/dialogsSettings";
import { KPIChannel } from "../../../../lib/kpi/types";

export type MetricsAbbreviation = {
  metaAds: "fbAds";
  GA4: "ga4";
  adsOverview: "conversions";
};

export const changeMetricsAbbreviation: MetricsAbbreviation = {
  metaAds: "fbAds",
  GA4: "ga4",
  [KPIChannel.adsOverview]: "conversions",
};

// TODO: CHECK GA4 groupName
export const modifyGroupName = (channel: string) =>
  channel === ("ga4" as keyof IKpiGroups) ? "GA4" : channel;
