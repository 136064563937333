import { kv } from "@vercel/kv";
import { Period } from "../../types";
import { getPrevPeriod } from "../google/utils";
import currencies from "./currencies.json";

export function formatNumber(
  number?: string | number | null,
  decimals?: number,
  minFractionalDigits?: number,
) {
  if (number == null) return "-";
  return Number(number).toLocaleString("en-US", {
    maximumFractionDigits: decimals ?? 2,
    minimumFractionDigits: minFractionalDigits ?? 0,
  });
}

export function getSumOfArrayElements(array: number[]) {
  return array.reduce((a, b) => a + b, 0);
}

export function getAverageOfArrayElements(array: number[]) {
  const arrayWithoutZeros = array.filter((item) => item !== 0);
  if (arrayWithoutZeros.length === 0) return 0;
  return getSumOfArrayElements(arrayWithoutZeros) / arrayWithoutZeros.length;
}

export const arraySplitHalf = <T>(array: Array<T>) => {
  const half = Math.floor(array.length / 2);
  const firstHalf = array.slice(0, half);
  const secondHalf = array.slice(half, array.length);
  return { firstHalf, secondHalf };
};

export function getGrowth(
  curValue: number | null | undefined,
  prevValue: number | null | undefined,
) {
  if (!curValue || !prevValue || prevValue == null || curValue == null) {
    return { value: "-", is_growing: false };
  }

  const change = Math.round(((curValue - prevValue) / Math.abs(prevValue)) * 100);
  const isGrowing = change > 0;

  const existingValue = isGrowing ? `${change}%` : `${change * -1}%`;
  return {
    value: !Number.isFinite(change) ? "-" : existingValue,
    is_growing: isGrowing,
  };
}

export function getComparisonPeriodDates({
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
}: Period) {
  let comparisonPeriodStart = prevStartDate || "";
  let comparisonPeriodEnd = prevEndDate || "";
  if (!prevStartDate || !prevEndDate) {
    const { prevPeriodStart, prevPeriodEnd } = getPrevPeriod(startDate, endDate);
    comparisonPeriodStart = prevPeriodStart;
    comparisonPeriodEnd = prevPeriodEnd;
  }
  return { comparisonPeriodStart, comparisonPeriodEnd };
}

export function getDate(dateString: any) {
  const date = dateString.split("T");
  return date[0];
}

export const getAPIDataCurrency = (currency: string) => {
  if (currency) {
    return currencies.filter((item) => item.value === currency)[0]?.symbol;
  }
  return "";
};

export const toTitleCase = (phrase: string) => {
  if (phrase)
    return phrase
      .replace(/_|-/g, " ")
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
};

export function chunkArray<T>(array: T[], size: number): T[][] {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
    array.slice(i * size, i * size + size),
  );
}

export async function setCacheWithExpiry({
  cacheKey,
  data,
  query,
  expiryTimeInSeconds = 0,
}: {
  cacheKey: string;
  data: any;
  query?: string;
  expiryTimeInSeconds?: number;
}) {
  const isQueryIncludesToday = query?.includes(new Date().toISOString().split("T")[0]);
  try {
    const dataSize = new TextEncoder().encode(JSON.stringify(data)).length;
    const MAX_CACHE_SIZE = 1048576; // 1MB in bytes

    if (dataSize > MAX_CACHE_SIZE) {
      console.log(`Data size (${dataSize} bytes) exceeds cache limit of ${MAX_CACHE_SIZE} bytes`);
      return;
    }

    await kv.set(cacheKey, data, {
      ex: expiryTimeInSeconds || (isQueryIncludesToday ? 60 : 60 * 10),
      nx: true,
    });
  } catch (error) {
    console.log("SET CACHE ERROR", error);
  }
}
