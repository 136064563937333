import { useMutation, useQuery } from "react-query";
import api, { queryFetcher } from "../APIResolvers";
import { useStoresContext } from "../../context/StoresContext";
import { useDateDropdown } from "../../context/DateDropdownContext";
import {
  ConversionsCards,
  LtvCards,
  StoreOverviewCards,
  TikTokCards,
} from "../../lib/pinned/types";
import { FBAdsCards } from "../../lib/facebook/types";
import { GoogleAdsCards, GSCCardsKeys, ReChargeCards } from "../../types";
import { GA4CardsKeys } from "../../lib/google/ga4/types";
import { KlaviyoCardsKeys } from "../../lib/klaviyo/types";
import { IActiveGroup } from "../pages/Kpi/types";
import { TGroupKeys } from "../pages/Kpi/components/DialogMetrics/dialogsSettings";
import { FetchMetricsResult } from "../../lib/kpi";
import { clearGroupOrderFromStorage } from "../pages/Dashboard/models/PinnedCardsDND/utils";

export type MetricName =
  | FBAdsCards
  | GoogleAdsCards
  | StoreOverviewCards
  | ConversionsCards
  | GA4CardsKeys
  | GSCCardsKeys
  | KlaviyoCardsKeys
  | LtvCards
  | TikTokCards
  | ReChargeCards;

export interface IKPIMetrics {
  channel: TGroupKeys;
  group: IActiveGroup;
  metricName: MetricName;
}

export interface IKPIMetricsDetails {
  channel: TGroupKeys;
  metricName: MetricName | string;
  groupId?: string;
}

export interface IKPIMetricsByGroupParams {
  group?: string;
  storeId: string;
}

// export type IMetricsMutationRequest = Omit<KPIMetric, "storeId">[]

export interface IMetricsMutationRequest {
  channel: TGroupKeys;
  metricName: MetricName;
  group: string;
  displayOrder?: number;
}

export interface IMetricsMutationResponse {
  channel: TGroupKeys;
  metricName: MetricName;
  group: string;
  storeId: string;
  displayOrder?: number;
}

enum Methods {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
}

export interface IKPIMetricsByPeriodResponse {
  [key: string]: IKpiMetricResponse;
}

export interface IKpiMetricResponse {
  value: number | string;
  periodValues: number[];
  period_values?: number[];
  prevPeriodValues: number[];
  growth: {
    value: string;
    is_growing: boolean;
  };
}

export const useOperateKPIMetrics = (groupId?: string, onSuccessCallback?: () => void) => {
  const { selectedStore } = useStoresContext();
  const storeId = selectedStore?.id || "";
  const isStoreIdValid = !!storeId && storeId.length > 0;
  const { dateRange, groupByValue, compareRange } = useDateDropdown();

  // List all metrics for a store
  const {
    data: allMetrics,
    refetch: refetchAllMetrics,
    isRefetching: isAllMetricsIsRefetching,
    isLoading: isLoadingMetricsIsRefetching,
  } = useQuery<IMetricsMutationResponse[]>(
    ["KPIMetrics", storeId],
    () => queryFetcher(api.kpiMetrics.ENDPOINTS.KPIMetrics(storeId)),
    {
      enabled: isStoreIdValid,
    },
  );

  // Get metric values for a group
  const {
    data: kpiMetricsByPeriod,
    refetch: refetchKpiMetricsByPeriod,
    isLoading: isLoadingKpiMetricsByPeriod,
  } = useQuery<FetchMetricsResult>(
    [
      "KPIMetricsByPeriod",
      storeId,
      // groupId,
      groupByValue,
      dateRange.from,
      dateRange.to,
      compareRange.prevStartDate,
      compareRange.prevEndDate,
    ],
    () =>
      queryFetcher(
        api.kpiMetrics.ENDPOINTS.KPIMetricsByPeriod({
          storeId,
          // groupId: groupId === "all" ? undefined : groupId,
          groupBy: groupByValue,
          startDate: dateRange.from,
          endDate: dateRange.to,
          prevStartDate: compareRange.prevStartDate,
          prevEndDate: compareRange.prevEndDate,
        }),
      ),
    {
      enabled: Boolean(isStoreIdValid && allMetrics),
    },
  );

  // List all metrics for a group for a store
  const {
    data: groupMetrics,
    refetch: refetchGroupMetrics,
    isLoading: isLoadingMetricsList,
  } = useQuery<IMetricsMutationResponse[]>(
    ["KPIGroupMetrics", storeId, groupId],
    () =>
      queryFetcher(
        api.kpiMetrics.ENDPOINTS.KPIMetricsByGroup({
          storeId,
          group: groupId === "all" ? undefined : groupId,
        }),
      ),
    {
      enabled: isStoreIdValid,
    },
  );

  // Create/update metrics
  const {
    mutate: addMetrics,
    isLoading: addMetricsLoading,
    isError: isAddMetricsError,
    reset: resetAddMetrics,
  } = useMutation(
    (metrics: IMetricsMutationRequest[]) =>
      queryFetcher(api.kpiMetrics.ENDPOINTS.addKPIMetrics(), {
        method: Methods.POST,
        body: JSON.stringify({ storeId, metrics }),
      }),

    {
      onSuccess: (_, metrics) => {
        refetchGroupMetrics();
        refetchAllMetrics();
        refetchKpiMetricsByPeriod();
        onSuccessCallback?.();
        metrics.forEach(({ group }) => clearGroupOrderFromStorage(group));
      },
    },
  );
  // Delete metrics
  const {
    mutate: deleteMetrics,
    isLoading: deleteMetricsLoading,
    isError: isDeleteMetricsError,
    reset: resetDeleteMetrics,
  } = useMutation(
    (metrics: IMetricsMutationRequest[]) =>
      queryFetcher(api.kpiMetrics.ENDPOINTS.deleteKPIMetrics(), {
        method: Methods.DELETE,
        body: JSON.stringify({ storeId, metrics }),
      }),
    {
      onSuccess: (_, metrics) => {
        refetchGroupMetrics();
        refetchAllMetrics();
        refetchKpiMetricsByPeriod();
        onSuccessCallback?.();
        metrics.forEach(({ group }) => clearGroupOrderFromStorage(group));
      },
    },
  );

  // Reorder metrics
  const {
    mutate: setReorderMetrics,
    isLoading: isReorderMetricsLoading,
    isError: isReorderMetricsError,
    reset: resetReorderMetrics,
  } = useMutation(
    (metrics: IMetricsMutationRequest[]) =>
      queryFetcher(api.kpiMetrics.ENDPOINTS.deleteKPIMetrics(), {
        method: Methods.POST,
        body: JSON.stringify({ storeId, metrics }),
      }),
    {
      onSuccess: (_, metrics) => {
        refetchGroupMetrics();
        refetchAllMetrics();
        refetchKpiMetricsByPeriod();
        onSuccessCallback?.();
        // metrics.forEach(({ group }) => clearGroupOrderFromStorage(group));
      },
    },
  );

  return {
    reorderMetrics: {
      setReorderMetrics,
      isReorderMetricsLoading,
      isReorderMetricsError,
      resetReorderMetrics,
    },
    kpiMetricsByPeriod,
    allMetrics,
    isAllMetricsIsRefetching,
    groupMetrics,
    addMetrics,
    deleteMetrics,
    resetAddMetrics,
    resetDeleteMetrics,
    isKpiMetricsError: isAddMetricsError || isDeleteMetricsError,
    isLoading: addMetricsLoading || deleteMetricsLoading,
    isLoadingKpiMetricsByPeriod,
    isLoadingMetricsList,
    refetchAllMetrics,
    refetchKpiMetricsByPeriod,
  };
};
