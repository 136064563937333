import { subYears } from "date-fns";
import { DateOptions } from "../../../src/utils/constants";

export const getPreviousYearDates = (
  parsedDateFrom: Date,
  parsedDateTo: Date,
  selectedDateIndex?: DateOptions,
) => {
  const prevFrom = subYears(parsedDateFrom, 1);
  const prevTo = subYears(parsedDateTo, 1);
  return {
    prevFrom,
    prevTo,
  };
};
