import { GroupByValues } from "../../types";
import { FbToken } from "../auth/auth-db";
import { AdsOverviewMetricKeys } from "./ads-overview/types";
import { GA4MetricKeys } from "./GA4";
import { GSCMetricKeys } from "./google-search-console";
import { GoogleAdsMetricKeys } from "./googleAds";
import { KlaviyoMetricKeys } from "./klaviyo";
import { LTVMetricKeys } from "./ltv";
import { MetaAdsMetricKeys } from "./metaAds";
import { RechargeMetricKeys } from "./recharge";
import { StoreOverviewMetricKeys } from "./store-overview";
import {
  FinanceKPIDailyDataType,
  StoreOverviewKPIDailyDataType,
} from "./store-overview/fetch-data";
import { TikTokMetricKeys } from "./tiktok";

export enum FinanceKPIMetrics {
  totalSales,
  productRevenue,
  shipping,
  discounts,
  refunds,
  taxes,
  netSales,
  COGS,
  productCosts,
  packingFees,
  shippingCosts,
  transactionFees,
  fulfillment,
  marketingOtherExpenses,
  facebook,
  googleAds,
  marketing,
  contributionMargin,
}

export enum FinanceKPIMetricsValues {
  totalSales = "Total Sales",
  productRevenue = "Product Revenue",
  shipping = "Shipping",
  discounts = "Discounts",
  refunds = "Refunds",
  taxes = "Taxes",
  netSales = "Net Sales",
  COGS = "COGS",
  productCosts = "Product Costs",
  packingFees = "Packing Fees",
  shippingCosts = "Shipping Costs",
  transactionFees = "Transaction Fees",
  fulfillment = "Fulfillment",
  marketingOtherExpenses = "Marketing Other Expenses",
  facebook = "Facebook",
  googleAds = "Google Ads",
  marketing = "Marketing",
  contributionMargin = "Contribution Margin",
}

type FinanceKPIMetricKeys = keyof typeof FinanceKPIMetrics;

export type FinanceKPIParams = {
  storeId: string;
  startDate: string;
  endDate: string;
  comparisonPeriodStart: string;
  comparisonPeriodEnd: string;
  googleAccessToken: string | null;
  fbToken: FbToken;
};

export type RawMetric = {
  currentPeriodTotalValue: number;
  currentPeriodDailyValues: number[];
  comparisonPeriodTotalValue: number;
  comparisonPeriodDailyValues: number[];
};

type PeriodValue = {
  period: string;
  value: number;
  trend: { value: string; isGrowing: boolean };
};

export type MetricFE = {
  currentPeriodTotalValue: number;
  currentPeriodValues: PeriodValue[];
  trend: { value: string; isGrowing: boolean };
};

export type KPIGroupByOption = "day" | "week" | "month" | "quarter" | "year";

export enum KPIChannel {
  storeOverview = "storeOverview",
  finance = "finance",
  metaAds = "metaAds",
  googleAds = "googleAds",
  klaviyo = "klaviyo",
  tiktok = "tiktok",
  GA4 = "GA4",
  recharge = "recharge",
  adsOverview = "adsOverview",
  googleSearchConsole = "googleSearchConsole",
}

export type KPIMetricKeys =
  | StoreOverviewMetricKeys
  | MetaAdsMetricKeys
  | GoogleAdsMetricKeys
  | FinanceKPIMetricKeys
  | KlaviyoMetricKeys
  | TikTokMetricKeys
  | GA4MetricKeys
  | RechargeMetricKeys
  | AdsOverviewMetricKeys
  | GSCMetricKeys
  | LTVMetricKeys;

export type MetricVsTarget = {
  value: number | string;
  target: number;
  result: boolean;
  trend: { value: string; isGrowing: boolean };
};

export type MetricVsPrevious = {
  value: number | string;
  previousValue: number | string;
  trend: { value: string; isGrowing: boolean };
};

export type KPIMetricCard = {
  value: number;
  prevTotalValue: number;
  periodValues: GroupByValues;
  growth: { value: string | number; is_growing: boolean } | undefined;
  prevPeriodValues: GroupByValues;
  target?: TargetResult;
};

export type TargetResult = {
  target: number | null;
  targetResult: {
    isOnTrack: boolean;
    trendValue: string;
  } | null;
};

export type StoreOverviewMetricExtractor = (item: StoreOverviewKPIDailyDataType[number]) => number;

export type FinanceMetricExtractor = (item: FinanceKPIDailyDataType[number]) => number;

export type MetricCalculator = ({
  dividendValue,
  divisorValue,
}: {
  dividendValue: number;
  divisorValue: number;
}) => number;

export type RatesAPI = { [date: string]: { [currency: string]: number } };

export type MetricTotalDailyValues = {
  curTotalValue: number;
  prevTotalValue: number;
  curDailyValues: number[];
  prevDailyValues: number[];
};

export type MetricConfig = {
  channel: KPIChannel;
  metric: string;
  displayOrder: number;
};
