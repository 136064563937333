import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import { DateOptions } from "../../../src/utils/constants";
import { FirstWeekDay } from "../../../types";
import { getWeekStartDay } from "../../../src/utils/getWeekStartDay";

export const getDefaultDateDropdownSettings = (
  selectedDateIndex: number,
  firstWeekDay: FirstWeekDay,
) => {
  let tempDate = new Date();
  let from = new Date();
  let to = new Date();

  const weekStartDay = getWeekStartDay(firstWeekDay);

  switch (selectedDateIndex) {
    case DateOptions.Today:
      break;
    case DateOptions.Yesterday:
      from = subDays(new Date(), 1);
      to = subDays(new Date(), 1);
      break;
    case DateOptions.LastSevenDays:
      from = startOfDay(subDays(new Date(), 7));
      to = endOfDay(subDays(new Date(), 1));
      break;

    case DateOptions.LastWeek:
      tempDate = subWeeks(new Date(), 1);
      from = startOfWeek(tempDate, { weekStartsOn: weekStartDay });
      to = endOfWeek(tempDate, { weekStartsOn: weekStartDay });
      break;
    case DateOptions.LastMonth:
      tempDate = subMonths(new Date(), 1);
      from = startOfMonth(tempDate);
      to = endOfMonth(tempDate);
      break;
    case DateOptions.LastThirtyDays:
      from = sub(new Date(), { days: 30 });
      to = sub(new Date(), { days: 1 });
      break;
    case DateOptions.LastSixtyDays:
      from = sub(new Date(), { days: 60 });
      to = sub(new Date(), { days: 1 });
      break;
    case DateOptions.LastNinetyDays:
      from = sub(new Date(), { days: 90 });
      to = sub(new Date(), { days: 1 });
      break;
    case DateOptions.LastOneTwentyDays:
      from = sub(new Date(), { days: 120 });
      to = sub(new Date(), { days: 1 });
      break;
    case DateOptions.LastThreeSixtyFiveDays:
      from = sub(new Date(), { days: 365 });
      to = sub(new Date(), { days: 1 });
      break;
    case DateOptions.LastThreeMonths:
      from = startOfMonth(subMonths(new Date(), 3));
      to = endOfMonth(new Date());
      break;
    case DateOptions.LastSixMonths:
      from = startOfMonth(subMonths(new Date(), 6));
      to = endOfMonth(subMonths(new Date(), 1));
      break;
    case DateOptions.LastNineMonths:
      from = startOfMonth(subMonths(new Date(), 9));
      to = endOfMonth(subMonths(new Date(), 1));
      break;
    case DateOptions.LastTwelveMonths:
      from = startOfMonth(subMonths(new Date(), 12));
      to = endOfMonth(subMonths(new Date(), 1));
      break;
    case DateOptions.LastTwentyFourMonths:
      from = startOfMonth(subMonths(new Date(), 24));
      to = endOfMonth(subMonths(new Date(), 1));
      break;
    case DateOptions.LastThirtySixMonths:
      from = startOfMonth(subMonths(new Date(), 36));
      to = endOfMonth(subMonths(new Date(), 1));
      break;
    case DateOptions.MTD:
      from = startOfMonth(new Date());
      to = new Date();
      if (from.getDate() !== new Date().getDate()) {
        to = subDays(new Date(), 1);
      }
      break;
    case DateOptions.LastYear:
      tempDate = subYears(new Date(), 1);
      from = startOfYear(tempDate);
      to = endOfYear(tempDate);
      break;
    case DateOptions.YTD:
      from = startOfYear(new Date());
      to = new Date();
      break;
    default:
      break;
  }

  return {
    from: format(from, "yyyy-MM-dd"),
    to: format(to, "yyyy-MM-dd"),
  };
};
