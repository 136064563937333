import { FirstWeekDay } from "../../types";

const weekStartDays = {
  [FirstWeekDay.MONDAY]: 1,
  [FirstWeekDay.SATURDAY]: 6,
  [FirstWeekDay.SUNDAY]: 0,
} as const; // Додаємо "as const" для строгих значень

export const getWeekStartDay = (firstWeekDay: FirstWeekDay): 0 | 1 | 6 =>
  weekStartDays[firstWeekDay];
